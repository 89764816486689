import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ExitPageComponent } from './pages/exit-page/exit-page.component';
import { TutorialExternalComponent } from './pages/tutorial-external/tutorial-external.component';
import { NonCompleannoPageComponent } from './pages/non-compleanno-page/non-compleanno-page.component';
import { CompleannoPageComponent } from './pages/compleanno-page/compleanno-page.component';
import { Sostenibilita1PageComponent } from './pages/sostenibilita1-page/sostenibilita1-page.component';
import { Sostenibilita2PageComponent } from './pages/sostenibilita2-page/sostenibilita2-page.component';
import { Sostenibilita3PageComponent } from './pages/sostenibilita3-page/sostenibilita3-page.component';

const appRoutes: Routes = [
  { path: 'non-compleanno', component: NonCompleannoPageComponent },
  { path: 'compleanno', component: CompleannoPageComponent },
  { path: 'sostenibilita-1', component: Sostenibilita1PageComponent },
  { path: 'sostenibilita-2', component: Sostenibilita2PageComponent },
  { path: 'sostenibilita-3', component: Sostenibilita3PageComponent },
  { path: 'tutorial-external', component: TutorialExternalComponent },
  { path: 'exit', component: ExitPageComponent },
  //{ path: '', redirectTo: '/compleanno', pathMatch: 'full' },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: "enabled"
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
