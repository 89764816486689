<div class="contenitore" *ngIf="!showReward">
  <div class="contenitore_titolo">Il regalo<br />per te!</div>

  <app-scratch-card
    imgStart="/assets/img/regalo_inizio.jpg"
    imgEnd="/assets/img/regalo_aperto.jpg"
    (showReward)="showReward = true"
    [percentage]="40"
  ></app-scratch-card>

  <div class="contenitore_descrizione">
    Scorri con il dito sopra il pacco regalo e scopri il tuo premio.
  </div>
</div>

<!-- ATTENZIONE - Mostrare questo contenuto dopo aver grattato la superficie della torta -->
<app-reward *ngIf="showReward"></app-reward>
