<div class="contenitore" *ngIf="!showReward">
  <div class="contenitore_titolo">
    Buon<br />
    Compleanno!
  </div>

  <app-scratch-card
    imgStart="/assets/img/torta_inizio.jpg"
    imgEnd="/assets/img/torta_spenta.jpg"
    [percentage]="15"
    (showReward)="showReward = true"
  ></app-scratch-card>

  <div class="contenitore_descrizione">
    Scorri con il dito sopra le candele per spegnerle ed ottenere il tuo regalo!
  </div>
</div>

<!-- ATTENZIONE - Mostrare questo contenuto dopo aver grattato la superficie della torta -->
<app-reward *ngIf="showReward"></app-reward>
