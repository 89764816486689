import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LoadingModule } from './loading/loading.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { AngularGestureConfig } from './angular-gesture-config';
import { TokenInterceptor } from './services/token-interceptor';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RouterModule } from '@angular/router';
import { AlertModule } from './alert/alert.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TutorialExternalComponent } from './pages/tutorial-external/tutorial-external.component';
import { ExitPageComponent } from './pages/exit-page/exit-page.component';
import { CompleannoPageComponent } from './pages/compleanno-page/compleanno-page.component';
import { NonCompleannoPageComponent } from './pages/non-compleanno-page/non-compleanno-page.component';
import { Sostenibilita1PageComponent } from './pages/sostenibilita1-page/sostenibilita1-page.component';
import { Sostenibilita2PageComponent } from './pages/sostenibilita2-page/sostenibilita2-page.component';
import { Sostenibilita3PageComponent } from './pages/sostenibilita3-page/sostenibilita3-page.component';
import { ModalModule } from './modal/modal.module';
import { RewardModule } from './reward/reward.module';
import { ScratchCardModule } from './scratch-card/scratch-card.module';

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    NotFoundPageComponent,
    TutorialExternalComponent,
    ExitPageComponent,
    CompleannoPageComponent,
    NonCompleannoPageComponent,
    Sostenibilita1PageComponent,
    Sostenibilita2PageComponent,
    Sostenibilita3PageComponent,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: AngularGestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    LoadingModule,
    AlertModule,
    HeaderModule,
    FooterModule,
    HammerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxUsefulSwiperModule,
    ModalModule,
    RewardModule,
    ScratchCardModule,
  ],
})
export class AppModule {}
