<div class="contenitore" *ngIf="!showReward">
  <div class="contenitore_titolo">Giornata mondiale del suolo</div>

  <app-scratch-card
    imgStart="/assets/img/suolo_1.jpg"
    imgEnd="/assets/img/suolo_2.jpg"
    (showReward)="showReward = true"
    [percentage]="60"
  ></app-scratch-card>

  <div class="contenitore_descrizione">
    Scorri con il dito e scopri come si può ripristinare anche l'ambiente più compromesso.
  </div>
</div>

<!-- ATTENZIONE - Mostrare questo contenuto dopo aver grattato la superficie -->
<app-reward *ngIf="showReward"></app-reward>
