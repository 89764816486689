<div class="contenitore" *ngIf="!showReward">
  <div class="contenitore_titolo" style="font-size: 26px; line-height: 30px;">Giornata<br />dell'alimentazione</div>

  <app-scratch-card
    imgStart="/assets/img/cibo_1.jpg"
    imgEnd="/assets/img/cibo_2.jpg"
    (showReward)="showReward = true"
    [percentage]="70"
  ></app-scratch-card>

  <div class="contenitore_descrizione">
    Scorri il dito e scopri come puoi ridurre lo spreco del cibo.
  </div>
</div>

<!-- ATTENZIONE - Mostrare questo contenuto dopo aver grattato la superficie -->
<app-reward *ngIf="showReward"></app-reward>
