import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sostenibilita3-page',
  templateUrl: './sostenibilita3-page.component.html',
  styleUrls: ['./sostenibilita3-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class Sostenibilita3PageComponent implements OnInit {
  showReward: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
