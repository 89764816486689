<!-- inizio contenitore -->
<div class="contenitore">
  <swiper [config]="config" #usefulSwiper>
    <div class="swiper-wrapper">
      <!-- inizio slide 1 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">
          BENVENUTO<br />
          AL PALIO DI<br />
          CONAD!
        </div>

        <div class="contenitore_foto">
          <img src="/assets/img/tutorial/img_tutorial_1.jpg" />
        </div>
      </div>
      <!-- fine slide 1 -->

      <!-- inizio slide 2 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">FAI LA SPESA<br />E PARTECIPA</div>

        <div class="contenitore_descrizione">
          <strong>Ogni 10€ di spesa</strong>, riceverai una giocata (per un
          massimo di 5 al giorno) che ti consentirà di girare la ruota e
          partecipare ai mini giochi.
        </div>

        <div class="contenitore_foto">
          <img src="/assets/img/tutorial/img_tutorial_2.jpg" />
        </div>
      </div>
      <!-- fine slide 2 -->

      <!-- inizio slide 3 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">RISPARMIA<br />E DIVERTITI</div>

        <div class="contenitore_descrizione">
          Accedi ogni giorno per
          <strong
            >guadagnare tanti buoni sconto Conad e di grandi marche
            selezionate</strong
          >, accumula punti con i mini giochi per
          <strong>vincere fantastici premi ogni edizione</strong> e ricevi
          preziosi consigli su come condurre
          <strong>una vita più sostenibile</strong>.<br />
          Attraverso la sezione "prova" potrai inoltre allenarti con i mini
          giochi in qualsiasi momento!
        </div>

        <div class="contenitore_foto" style="align-items: flex-end">
          <img src="/assets/img/tutorial/img_tutorial_3.jpg" />
        </div>
      </div>
      <!-- fine slide 3 -->

      <!-- inizio slide 4 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">PREMIA TE<br />E L'AMBIENTE</div>

        <div class="contenitore_descrizione">
          In base ai risultati ottenuti nei mini giochi, guadagnerai delle
          "<strong>Margherite Verdi</strong>" che ti serviranno per
          <strong
            >partecipare all'estrazione finale dei super premi e attivare dei
            progetti di sostenibilità ambientale</strong
          >.<br />
          Alla fine più Margherite Verdi avrai accumulato, più probabilità avrai
          di vincere e più aiuterai Conad ad avviare i progetti di
          sostenibilità.
        </div>

        <div class="contenitore_foto" style="padding: 0 30px">
          <img src="/assets/img/tutorial/img_tutorial_4.jpg" />
        </div>
      </div>
      <!-- fine slide 4 -->

      <!-- inizio slide 5 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">SOSTIENI<br />LA TUA SQUADRA</div>

        <div class="contenitore_descrizione">
          I punti che otterrai incideranno anche sulla classifica generale delle
          regioni.
          <strong
            >Aiuta la tua squadra ad attivare il suo progetto di sostenibilità
            ambientale</strong
          >, rendendo più verde il proprio territorio e a
          <strong>vincere il premio finale</strong>:<br />
          la "<span style="color: #f58d02">Tiny Forest</span>"!
        </div>

        <div class="contenitore_foto" style="padding: 0 30px">
          <img src="/assets/img/tutorial/img_tutorial_5.jpg" />
        </div>
      </div>
      <!-- fine slide 5 -->

      <!-- inizio slide 6 -->
      <div class="swiper-slide contenitore_tutorial">
        <div class="contenitore_titolo">CORRI<br />A GIOCARE</div>

        <div class="contenitore_descrizione">
          Cosa aspetti?<br />
          <strong>Inizia subito a giocare al<br />Palio di Conad!</strong>
        </div>

        <div class="contenitore_foto" style="align-items: flex-end">
          <img src="/assets/img/tutorial/img_tutorial_6.jpg" />
        </div>
      </div>
      <!-- fine slide 6 -->
    </div>
    <div
      class="swiper-pagination paginatore_tutorial"
      aria-hidden="true"
      role="none"
    ></div>
  </swiper>
</div>
<!-- fine contenitore -->
