<div class="contenitore" *ngIf="!showReward">
  <div class="contenitore_titolo">Giornata della Terra</div>

  <app-scratch-card
    imgStart="/assets/img/giornata_della_terra_2.jpg"
    imgEnd="/assets/img/giornata_della_terra_1.jpg"
    (showReward)="showReward = true"
    [percentage]="60"
  ></app-scratch-card>

  <div class="contenitore_descrizione">
    Scorri con il dito sopra il pianeta terra per ripulirlo e renderlo rigoglioso.
  </div>
</div>

<!-- ATTENZIONE - Mostrare questo contenuto dopo aver grattato la superficie -->
<app-reward *ngIf="showReward"></app-reward>
