import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class RewardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onClickExit() {
    window.location.href = environment.gameUrl + '/gioca';
  }
}
