export const KEY_AUTH_USER = "auth_user";

export interface User {

    user: {

        userId: string;

        fidelityCard: string;

        userGameId: string;
        
        name: string;

        signature: number;
    }

    info: {

        worldId: number;

        accessibility: 0|1;
    }
    
    auth: JwtAccessToken;
}

export interface JwtAccessToken {

    jwtToken: string;
    
    refreshToken: string;
}
