<app-header></app-header>
<router-outlet></router-outlet>
<!-- Routed components go here -->

<app-loading></app-loading>

<app-alert></app-alert>
<!-- inizio pop esci dal gioco -->
<app-modal></app-modal>
<!-- fine pop esci dal gioco -->
