import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RewardComponent } from './reward/reward.component';

@NgModule({
  declarations: [RewardComponent],
  imports: [CommonModule],
  exports: [RewardComponent],
})
export class RewardModule {}
