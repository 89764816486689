<div class="contenitore contenitore_risultato" @fadeIn>
  <div class="contenitore_titolo">Fantastico!</div>

  <div class="contenitore_punteggio">
    <span>Hai ottenuto:</span>
    <u>1</u>
    <img src="/assets/img/giro_di_ruota.svg" />
    <span><strong>Giro di ruota</strong><br />per gioco del Palio</span>
  </div>

  <div class="contenitore_punteggio">
    <span>Hai ottenuto:</span>
    <u style="color: #5eb134">1</u>
    <img src="/assets/img/punto_margherita.svg" />
    <span
      ><strong>Punto Margherita Verde</strong><br />per il gioco del Palio</span
    >
  </div>
  <div class="contenitore_vai">
    <div class="tasto_principale" (click)="onClickExit()">Vai al gioco</div>
  </div>
</div>
