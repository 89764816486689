<!-- ATTENZIONE - Per mostrare la popuop aggiungere la classe "pop_gioco_visibile" a "pop_gioco" -->
<div
  class="pop_gioco"
  [ngClass]="{ pop_gioco_visibile: modalService.showModal$ | async }"
>
  <div class="pop_gioco_container">
    <div id="popup_esci_gioco_titolo" class="pop_gioco_container_titolo">
      Vuoi tornare all'app Conad?
    </div>

    <div class="pop_gioco_container_azioni">
      <div class="tasto_principale" (click)="onNoClick()">NO</div>
      <div class="tasto_principale" (click)="onYesClick()">SI</div>
    </div>
  </div>

  <div class="pop_gioco_bg"></div>
</div>
