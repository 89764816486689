import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtAccessToken, KEY_AUTH_USER, User } from '../interfaces/user';
import { LocalService } from './local.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  authUser: User|null = null;

  constructor(
    public local: LocalService,
    private http: HttpClient
  ) { }

  /**
   * Save data User
   * @param user 
   */
  saveAuthUser() {

    if (this.authUser == null) {

      this.removeAuthUser();
    
    } else {

      this.local.saveData(KEY_AUTH_USER, JSON.stringify(this.authUser));
    }
  }

  /**
   * Get data User
   * @returns 
   */
  getAuthUser(): User|null {

    const data = this.local.getData(KEY_AUTH_USER);

    if (data == null) {

      this.authUser = null;

      return null;
    }

    const user: User = JSON.parse(data);

    this.authUser = user;

    return user;
  }

  /**
   * Remove data User
   */
  removeAuthUser() {

    this.authUser = null;

    this.local.removeData(KEY_AUTH_USER);
  }

  /**
   * Restituisce l'ID del Mondo in base alla data attuale
   * @returns 
   */
  getWorldIdByDate(): number {

    const dateNow = new Date();

    const dateWorld2 = new Date("2023-07-06T00:00:00"); // date start worldId 2

    const dateWorld3 = new Date("2023-09-28T00:00:00"); // date start worldId 3

    const dateWorld4 = new Date("2023-12-13T00:00:00"); // date start worldId 4

    let worldId = 1; // default

    if (dateNow.getTime() >= dateWorld2.getTime() && dateNow.getTime() < dateWorld3.getTime()) {

      worldId = 2;
    }

    if (dateNow.getTime() >= dateWorld3.getTime() && dateNow.getTime() < dateWorld4.getTime()) {

      worldId = 3;
    }

    if (dateNow.getTime() >= dateWorld4.getTime()) {

      worldId = 4;
    }

    return worldId;
  }

  /**
   * Invia rihiesta per aggiornare il token di autenticazione 
   */
  userAuthToken() {

    let url = API_URL + "/api/auth/auths/refresh";

    let params = {refreshToken: (this.authUser) ? this.authUser.auth.refreshToken : ""};

    return this.http.post<JwtAccessToken>(url, params).pipe(
      tap((data) => {

        if (this.authUser != null) {

          this.authUser.auth = {
            jwtToken: data.jwtToken,
            refreshToken: data.refreshToken
          };
  
          this.saveAuthUser();
        }
      })
    );
  }

  /**
   * Recupera i dati utente dal token di accesso passato dall'applicazione
   * @param token 
   * @returns 
   */
  authsAuth(token: string) {

    const body = { "authToken": token };

    return this.http.post<User>(API_URL + "/api/auth/auths/auth", body);
  }

}
