import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sostenibilita2-page',
  templateUrl: './sostenibilita2-page.component.html',
  styleUrls: ['./sostenibilita2-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class Sostenibilita2PageComponent implements OnInit {
  showReward: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
