<div class="header">
  <img class="header_logo" src="/assets/img/logo_hey_conad.png" />

  <svg
    class="header_button"
    viewBox="0 -960 960 960"
    (click)="modalService.openModal()"
  >
    <path
      d="M242.462-202.462h145.23v-203.692q0-13 8.981-21.981 8.981-8.98 21.981-8.98h123.077q12.558 0 21.567 8.98 9.01 8.981 9.01 21.981v203.692h145.23v-344.743q0-6.154-2.692-11.154t-7.307-8.846L494.615-727.539q-6.154-5.384-14.615-5.384t-14.615 5.384L252.461-567.205q-4.615 3.846-7.307 8.846t-2.692 11.154v344.743Zm-36.924 0v-344.615q0-14.803 6.32-27.692 6.32-12.89 18.296-21.539l212.923-161.231q16.446-12.307 36.877-12.307t36.969 12.307l212.923 161.231q11.976 8.649 18.296 21.539 6.32 12.889 6.32 27.692v344.615q0 14.808-11.058 25.866t-25.866 11.058H566.346q-13 0-21.981-8.981-8.98-8.981-8.98-21.981v-203.692h-110.77V-196.5q0 13-8.997 21.981-8.997 8.981-21.537 8.981H242.292q-14.614 0-25.684-11.058t-11.07-25.866Zm274.462-266Z"
    />
  </svg>
</div>
