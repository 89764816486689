import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sostenibilita1-page',
  templateUrl: './sostenibilita1-page.component.html',
  styleUrls: ['./sostenibilita1-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class Sostenibilita1PageComponent implements OnInit {
  showReward: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
