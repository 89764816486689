import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  constructor(public modalService: ModalService) {}

  ngOnInit(): void {}

  onNoClick() {
    this.modalService.closeModal();
  }

  onYesClick() {
    this.modalService.closeModal();
    // this.router.navigateByUrl('/exit');
    this.onClickExit();
  }

  onClickExit() {
    window.location.href = environment.gameUrl + '/exit';
  }
}
