import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-scratch-card',
  templateUrl: './scratch-card.component.html',
  styleUrls: ['./scratch-card.component.css'],
})
export class ScratchCardComponent implements AfterViewInit {
  @Input() imgStart: string = '';
  @Input() imgEnd: string = '';
  @Input() percentage: number = 25;
  @Output() showReward: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('scratchCanvas') canvasRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') canvasContainer!: ElementRef<HTMLDivElement>;

  private ctx!: CanvasRenderingContext2D;
  private isDrawing = false;

  constructor() {}

  ngAfterViewInit(): void {
    this.loadImageAndSetupCanvas();
  }

  private loadImageAndSetupCanvas(): void {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d')!;
    const imgStart = new Image();
    imgStart.onload = () => {
      canvas.width = this.canvasContainer.nativeElement.offsetWidth;
      canvas.height = this.canvasContainer.nativeElement.offsetHeight;
      this.ctx.drawImage(imgStart, 0, 0, canvas.width, canvas.height);
      this.setupEventListeners();
    };
    imgStart.src = this.imgStart;
  }

  private setupEventListeners(): void {
    const canvas = this.canvasRef.nativeElement;
    canvas.addEventListener('mousedown', this.startDrawing.bind(this), false);
    canvas.addEventListener('mousemove', this.draw.bind(this), false);
    canvas.addEventListener('mouseup', this.stopDrawing.bind(this), false);
    canvas.addEventListener('mouseout', this.stopDrawing.bind(this), false);
    canvas.addEventListener('touchstart', this.startDrawing.bind(this), false);
    canvas.addEventListener('touchmove', this.draw.bind(this), false);
    canvas.addEventListener('touchend', this.stopDrawing.bind(this), false);
  }

  private startDrawing(event: MouseEvent | TouchEvent): void {
    this.isDrawing = true;
    this.draw(event);
  }

  private draw(event: MouseEvent | TouchEvent): void {
    if (!this.isDrawing) return;
    event.preventDefault();
    const mousePos = this.getMousePos(event);
    this.ctx.globalCompositeOperation = 'destination-out';
    this.ctx.beginPath();
    this.ctx.arc(mousePos.x, mousePos.y, 20, 0, Math.PI * 2);
    this.ctx.fill();

    const erasedAreaPercentage = this.calculateErasedArea();

    if (erasedAreaPercentage > this.percentage) {
      this.showReward.emit();
    }
  }
  private calculateErasedArea(): number {
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d')!;
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imgData.data;
    let erasedPixels = 0;

    for (let i = 0; i < pixels.length; i += 4) {
      if (pixels[i + 3] === 0) {
        erasedPixels++;
      }
    }
    const totalPixels = canvas.width * canvas.height;
    return (erasedPixels / totalPixels) * 100;
  }

  private stopDrawing(): void {
    this.isDrawing = false;
  }

  private getMousePos(event: MouseEvent | TouchEvent): {
    x: number;
    y: number;
  } {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clientX =
      event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY =
      event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  }
}
