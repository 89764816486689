import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScratchCardComponent } from './scratch-card/scratch-card.component';

@NgModule({
  declarations: [ScratchCardComponent],
  imports: [CommonModule],
  exports: [ScratchCardComponent],
})
export class ScratchCardModule {}
